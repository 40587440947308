export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"msapplication-TileColor","content":"#ffffff"},{"name":"theme-color","content":"#ffffff"},{"name":"google-site-verification","content":"d-Aa5LsfX2TWWrcfgF5c66wEl_F-GEFIekoyKvepWvw"}],"link":[{"rel":"preconnect","href":"https://static.cdn.prismic.io/","crossorigin":""},{"rel":"preconnect","href":"https://beqom-com.cdn.prismic.io/","crossorigin":""},{"rel":"preconnect","href":"https://images.prismic.io/","crossorigin":""},{"rel":"preconnect","href":"https://va.vercel-scripts.com/","crossorigin":""},{"rel":"preconnect","href":"https://www.googletagmanager.com/","crossorigin":""},{"rel":"preconnect","href":"https://cdn.usefathom.com/","crossorigin":""},{"rel":"preconnect","href":"https://js.hsforms.net/","crossorigin":""},{"rel":"preload","href":"/fonts/dm-sans-v14-latin-500.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/dm-sans-v14-latin-600.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/dm-sans-v14-latin-regular.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"apple-touch-icon","type":"image/png","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"mask-icon","color":"#000000","href":"/safari-pinned-tab.svg"},{"rel":"manifest","href":"/site.webmanifest"}],"style":[],"script":[{"src":"//js.hsforms.net/forms/embed/v2.js","defer":true}],"noscript":[],"title":"beqom","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"