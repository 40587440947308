import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import hotfix_45for_45spaces_45global from "/vercel/path0/middleware/hotfixForSpaces.global.ts";
import keep_45utm_45params_45global from "/vercel/path0/middleware/keepUtmParams.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  hotfix_45for_45spaces_45global,
  keep_45utm_45params_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}