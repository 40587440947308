import { default as PrismicPreviewJ01p9R2IH8Meta } from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as _91uid_93UXkUdxnEpKMeta } from "/vercel/path0/pages/[uid].vue?macro=true";
import { default as _91uid_939u0C4Z1XyDMeta } from "/vercel/path0/pages/blog/[uid].vue?macro=true";
import { default as _91uid_93mDULkdC8A4Meta } from "/vercel/path0/pages/events/[uid].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91uid_9365fRS3fjZKMeta } from "/vercel/path0/pages/lp/[uid].vue?macro=true";
import { default as _91uid_93TOt4dD0egDMeta } from "/vercel/path0/pages/news/[uid].vue?macro=true";
import { default as _91uid_93iiC35javayMeta } from "/vercel/path0/pages/products/[uid].vue?macro=true";
import { default as _91uid_93l4Gv9U007mMeta } from "/vercel/path0/pages/resources/[[category]]/[uid].vue?macro=true";
import { default as slice_45simulatorXNBTl3WVLzMeta } from "/vercel/path0/pages/slice-simulator.vue?macro=true";
import { default as _91uid_93MUIiTlg2gOMeta } from "/vercel/path0/pages/solutions/[uid].vue?macro=true";
import { default as _91uid_93VApLTUBPTjMeta } from "/vercel/path0/pages/success-stories/[uid].vue?macro=true";
export default [
  {
    name: "prismic-preview___en-us",
    path: "/preview",
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "prismic-preview___de-de",
    path: "/de-de/preview",
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "prismic-preview___fr-fr",
    path: "/fr-fr/preview",
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "uid___en-us",
    path: "/:uid()",
    component: () => import("/vercel/path0/pages/[uid].vue")
  },
  {
    name: "uid___de-de",
    path: "/de-de/:uid()",
    component: () => import("/vercel/path0/pages/[uid].vue")
  },
  {
    name: "uid___fr-fr",
    path: "/fr-fr/:uid()",
    component: () => import("/vercel/path0/pages/[uid].vue")
  },
  {
    name: "blog-uid___en-us",
    path: "/blog/:uid()",
    component: () => import("/vercel/path0/pages/blog/[uid].vue")
  },
  {
    name: "blog-uid___de-de",
    path: "/de-de/blog/:uid()",
    component: () => import("/vercel/path0/pages/blog/[uid].vue")
  },
  {
    name: "blog-uid___fr-fr",
    path: "/fr-fr/blog/:uid()",
    component: () => import("/vercel/path0/pages/blog/[uid].vue")
  },
  {
    name: "events-uid___en-us",
    path: "/events/:uid()",
    component: () => import("/vercel/path0/pages/events/[uid].vue")
  },
  {
    name: "events-uid___de-de",
    path: "/de-de/events/:uid()",
    component: () => import("/vercel/path0/pages/events/[uid].vue")
  },
  {
    name: "events-uid___fr-fr",
    path: "/fr-fr/events/:uid()",
    component: () => import("/vercel/path0/pages/events/[uid].vue")
  },
  {
    name: "index___en-us",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___de-de",
    path: "/de-de",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___fr-fr",
    path: "/fr-fr",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "lp-uid___en-us",
    path: "/lp/:uid()",
    component: () => import("/vercel/path0/pages/lp/[uid].vue")
  },
  {
    name: "lp-uid___de-de",
    path: "/de-de/lp/:uid()",
    component: () => import("/vercel/path0/pages/lp/[uid].vue")
  },
  {
    name: "lp-uid___fr-fr",
    path: "/fr-fr/lp/:uid()",
    component: () => import("/vercel/path0/pages/lp/[uid].vue")
  },
  {
    name: "news-uid___en-us",
    path: "/news/:uid()",
    component: () => import("/vercel/path0/pages/news/[uid].vue")
  },
  {
    name: "news-uid___de-de",
    path: "/de-de/news/:uid()",
    component: () => import("/vercel/path0/pages/news/[uid].vue")
  },
  {
    name: "news-uid___fr-fr",
    path: "/fr-fr/news/:uid()",
    component: () => import("/vercel/path0/pages/news/[uid].vue")
  },
  {
    name: "products-uid___en-us",
    path: "/products/:uid()",
    component: () => import("/vercel/path0/pages/products/[uid].vue")
  },
  {
    name: "products-uid___de-de",
    path: "/de-de/products/:uid()",
    component: () => import("/vercel/path0/pages/products/[uid].vue")
  },
  {
    name: "products-uid___fr-fr",
    path: "/fr-fr/products/:uid()",
    component: () => import("/vercel/path0/pages/products/[uid].vue")
  },
  {
    name: "resources-category-uid___en-us",
    path: "/resources/:category?/:uid()",
    component: () => import("/vercel/path0/pages/resources/[[category]]/[uid].vue")
  },
  {
    name: "resources-category-uid___de-de",
    path: "/de-de/resources/:category?/:uid()",
    component: () => import("/vercel/path0/pages/resources/[[category]]/[uid].vue")
  },
  {
    name: "resources-category-uid___fr-fr",
    path: "/fr-fr/resources/:category?/:uid()",
    component: () => import("/vercel/path0/pages/resources/[[category]]/[uid].vue")
  },
  {
    name: "slice-simulator___en-us",
    path: "/slice-simulator",
    component: () => import("/vercel/path0/pages/slice-simulator.vue")
  },
  {
    name: "slice-simulator___de-de",
    path: "/de-de/slice-simulator",
    component: () => import("/vercel/path0/pages/slice-simulator.vue")
  },
  {
    name: "slice-simulator___fr-fr",
    path: "/fr-fr/slice-simulator",
    component: () => import("/vercel/path0/pages/slice-simulator.vue")
  },
  {
    name: "solutions-uid___en-us",
    path: "/solutions/:uid()",
    component: () => import("/vercel/path0/pages/solutions/[uid].vue")
  },
  {
    name: "solutions-uid___de-de",
    path: "/de-de/solutions/:uid()",
    component: () => import("/vercel/path0/pages/solutions/[uid].vue")
  },
  {
    name: "solutions-uid___fr-fr",
    path: "/fr-fr/solutions/:uid()",
    component: () => import("/vercel/path0/pages/solutions/[uid].vue")
  },
  {
    name: "success-stories-uid___en-us",
    path: "/success-stories/:uid()",
    component: () => import("/vercel/path0/pages/success-stories/[uid].vue")
  },
  {
    name: "success-stories-uid___de-de",
    path: "/de-de/success-stories/:uid()",
    component: () => import("/vercel/path0/pages/success-stories/[uid].vue")
  },
  {
    name: "success-stories-uid___fr-fr",
    path: "/fr-fr/success-stories/:uid()",
    component: () => import("/vercel/path0/pages/success-stories/[uid].vue")
  }
]