/**
 * This should evaluate to true for all pages categorized under
 * /lp/name-of-page and nothing else.
 */

export default (): Ref<boolean> => {
  const route = useRoute()

  const isLandingPage = computed(
    (): boolean =>
      !!(route.name && typeof route.name === 'string' && route.name.includes('lp-uid')),
  )
  return isLandingPage
}
