import { type LinkResolverFunction } from '@prismicio/client'

const linkResolver: LinkResolverFunction = (doc) => {
  const isLandingPage = useIsLandingPage()

  // On landing pages we want to point all contact form cta's to the form on that particular page.
  if (isLandingPage.value && doc.url === '/en-us/contact-us') {
    return doc.url.replace('/en-us/contact-us', '')
  }

  /**
   * Remove en-us/ if it part of the url.
   * Remove undefined/ if it part of the url (the string 'undefined' is used as a category for e.g. Resources as a workaround).
   */

  if (doc.url !== undefined) {
    return doc.url.replace('en-us/', '').replace('undefined/', '')
  }

  return doc.url
}

export default linkResolver
