export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) {
    // Ignore on the server.
    return
  }

  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

  type UTMQueryParams = {
    [key: string]: string
  }

  // Extract UTM parameters from "from.query".
  const existingUtmQueryParams = utmParams.reduce((acc: UTMQueryParams, param) => {
    if (from.query[param]) {
      acc[param] = from.query[param] as string
    }
    return acc
  }, {} as UTMQueryParams)

  const newQuery = { ...to.query, ...existingUtmQueryParams }

  if (JSON.stringify(newQuery) !== JSON.stringify(to.query)) {
    return navigateTo({ ...to, query: newQuery })
  }
})
