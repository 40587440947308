<script setup lang="ts">
const props = defineProps({
  label: { type: String, required: true },
  type: { type: String, required: false },
  icon: { type: Boolean, required: false },
  url: { type: String, required: false },
  target: { type: String, required: false },
})
</script>

<template>
  <template v-if="label && label">
    <a
      v-if="type == 'primary'"
      :href="url"
      :target="target"
      class="inline-block bg-beqom-red text-white border border-beqom-red rounded-full px-4 py-2 hover:bg-opacity-70 transition-all duration-300 font-medium"
    >
      {{ label }}
    </a>
    <a
      v-else-if="type == 'link'"
      :href="url"
      :target="target"
      class="inline-flex items-center text-beqom-red hover:opacity-70 transition-all duration-300 font-medium"
    >
      {{ label }}
      <svg
        v-if="icon"
        class="ml-2"
        width="8"
        height="13"
        viewBox="0 0 8 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.96028 6.14234C7.17996 6.36202 7.17996 6.71812 6.96028 6.93779L1.22541 12.6727C1.00573 12.8923 0.649631 12.8923 0.429956 12.6727L0.164756 12.4075C-0.0549187 12.1878 -0.0549187 11.8317 0.164756 11.612L5.23671 6.54007L0.164756 1.46812C-0.0549186 1.24844 -0.0549186 0.892341 0.164756 0.672667L0.429956 0.407467C0.649631 0.187792 1.00573 0.187792 1.22541 0.407467L6.96028 6.14234Z"
          fill="#C30014"
        />
      </svg>
    </a>
    <a
      v-else
      :href="url"
      :target="target"
      class="inline-block text-beqom-red bg-red bg-red border border-beqom-red rounded-full px-4 py-2 hover:bg-beqom-red hover:text-white transition-all duration-300 font-medium"
    >
      {{ label }}
    </a>
  </template>
</template>
